import { useEffect } from 'react';
import logo from './logo.png';
import './styles/App.css';
import { useState } from 'react';
//import HistorySection from './HistorySection';

export default function App() {
  let [name, setName] = useState("Mikael")

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const name = queryParams.get("name")
    if (name !== "" && name !== null) {
      const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1)
      setName(capitalizedName) 
    }
  }, [setName])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="Champ-title">{name}</p>
        {/*
        <p className="Whos-the-loser">... and who's the loser?</p>
        <p className="Loser-title">Vetle</p>

        <div className="Vertical-spacer-100"></div>
        <HistorySection />
  */}
      </header>
    </div>
  );
}
